exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-albums-mdx-slug-js": () => import("./../../../src/pages/albums/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-albums-mdx-slug-js" */),
  "component---src-pages-albums-parasite-js": () => import("./../../../src/pages/albums/parasite.js" /* webpackChunkName: "component---src-pages-albums-parasite-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */)
}

